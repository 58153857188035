import { ref, nextTick } from 'vue';
import { scrollToBottom, html2text } from '@/utils';
import { ElMessage } from 'element-plus';
import copyText from '@/utils/copyText';
import { apiGptLike, apiGptReply } from '@/request/aiApi';

export default (reportConfigId, chatLoading, chatList, askQuestion, maxLength) => {

    const editVal = ref('');// 编辑问题

    // 打开编辑
    function openEdit(index, list) {
        if (chatLoading.value) {
            ElMessage.error('正在思考中，请稍等...')
            return;
        }
        list.forEach((item, i) => {
            if (i === index) {
                item.edit = true;
                editVal.value = item.question;
            } else {
                item.edit = false;
            }
        });
    }

    // 关闭编辑
    function closeEdit(index, list) {
        list[index].edit = false;
        editVal.value = '';
    }

    // 保存编辑
    function saveEdit(index, list) {
        if (chatLoading.value) return;
        const content = html2text(editVal.value);
        if (!content) {
            ElMessage.error('内容不能为空哟');
            return;
        }
        if (content.length > maxLength) {
            ElMessage.error(`内容不能超过${maxLength}字`);
            return;
        }
        // 将提问加入列表
        chatList.value.push({
            question: content,
            edit: false
        });
        askQuestion(content);// 发送请求
        closeEdit(index, list);
        nextTick(() => { scrollToBottom('.content_box') });
    }

    // 关闭所有编辑
    function closeAllEdit() {
        chatList.value.forEach(item => {
            item.edit = false;
        });
    }

    // 复制回答
    function copyAnswer(index, list) {
        const answer = list[index].answer;
        if (!answer) {
            ElMessage.error('暂无回答');
            return;
        }
        copyText(answer);
        ElMessage.success('复制成功');
    }

    // 点赞/踩 略,1-点赞,2-点踩
    function handleLike(index, list, operate) {
        const item = list[index];
        if (item.operate === operate) {
            item.operate = 0;
        } else {
            item.operate = operate;
        }
        apiGptLike({ chatId: item.chatId, operate, reportConfigId });
    }

    // 重新回答
    async function handleReply(index, list) {
        chatLoading.value = true;
        try {
            const item = list[index];
            const { code, data } = await apiGptReply({ chatId: item.chatId, reportConfigId });
            if (code !== '0') return;
            item.answer = data?.answer || '';
            item.chatId = data?.chatId || null;
            item.operate = 0;
        } finally {
            chatLoading.value = false;
        }
    }

    return { editVal, openEdit, closeEdit, saveEdit, closeAllEdit, copyAnswer, handleLike, handleReply }
}