
<template>
    <div class="textarea_container">
        <p class="placeholder" v-if="!modelValue" @click="handelFocus">试试说点什么...</p>
        <div class="textarea scrollbar" ref="textarea" contenteditable="true" @input="inputChange"
            @keydown.enter="handleEnter">
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';

const props = defineProps({
    modelValue: { type: String, default: '' }
})
const emits = defineEmits(['update:modelValue', 'submit'])
const textarea = ref(null)
const val = ref('');

// 输入
const inputChange = () => {
    let text = textarea.value?.innerHTML;
    text = text?.replace(/&nbsp;/g, ' ');
    val.value = text || '';
    emits('update:modelValue', text);
}

// 回车提交
const handleEnter = (e) => {
    e.preventDefault();// 阻止换行
    emits('submit', val.value || '');
}

const handelFocus = () => {
    textarea.value && textarea.value.focus();
}

watch(() => props.modelValue, (newVal) => {
    if (!newVal) {
        textarea.value.innerHTML = '';
        val.value = '';
    }
})

onMounted(() => {
    // 回显
    if (props.modelValue && textarea.value) {
        textarea.value.innerHTML = props.modelValue;
        val.value = props.modelValue;
    }
    setTimeout(() => {
        handelFocus();
    }, 500)
})
</script>

<style scoped lang="scss">
.textarea_container {
    flex: 1;
    position: relative;

    .textarea {
        width: 100%;
        min-width: 300px;
        min-height: 40px;
        max-height: 150px;
        overflow-y: auto;
        padding: 14px 16px;
        outline: none;
        color: #333;
        letter-spacing: 1px;
        line-height: 1.5em;

        &:focus {
            outline: 1px solid #005bac;
            outline-offset: -1px;
            border-radius: 8px;
        }
    }

    .placeholder {
        position: absolute;
        top: 14px;
        left: 16px;
        color: #7F8890;
        z-index: 0;
    }
}
</style>
