import { apiGptSetting } from '@/request/aiApi';
import { ref } from 'vue';

export default (reportConfigId) => {
    const reportConfig = ref({});

    async function getConfig() {
        const { data } = await apiGptSetting({ reportConfigId });
        reportConfig.value = data || {};
        if (data?.reportName) {
            document.title = data.reportName;
        }
    }

    getConfig();

    return { reportConfig }
}