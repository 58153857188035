<template>
    <section class="le_box">
        <div class="logo">
            <!-- <img src="@/assets/img/ai/header_logo.png" alt="" srcset=""> -->
            <img :src="reportConfig.logo" alt="" srcset="">
        </div>
        <div class="new_btn">
            <button @click="handleClick(null)">新建对话</button>
        </div>
        <div class="list scrollbar">
            <div class="list_item">
                <p class="tit">今天</p>
                <ul>
                    <li v-for="item in todayList" :key="item.subjectId"
                        :class="item.subjectId === modelValue ? 'active' : ''" @click="handleClick(item.subjectId)">
                        {{ item.subjectTitle }}
                        <div class="del" @click.stop="delTalk(item.subjectId)"><i class="iconfont icon-shanchu"></i></div>
                    </li>
                </ul>
            </div>
            <div class="list_item">
                <p class="tit">最近30天</p>
                <ul>
                    <li v-for="item in day30List" :key="item.subjectId"
                        :class="item.subjectId === modelValue ? 'active' : ''" @click="handleClick(item.subjectId)">
                        {{ item.subjectTitle }}
                        <div class="del" @click.stop="delTalk(item.subjectId)"><i class="iconfont icon-shanchu"></i></div>
                    </li>
                </ul>
            </div>
            <div class="list_item">
                <p class="tit">更早</p>
                <ul>
                    <li v-for="item in historyList" :key="item.subjectId"
                        :class="item.subjectId === modelValue ? 'active' : ''" @click="handleClick(item.subjectId)">
                        {{ item.subjectTitle }}
                        <div class="del" @click.stop="delTalk(item.subjectId)"><i class="iconfont icon-shanchu"></i></div>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script setup>
import { ref } from 'vue';
import { apiGptList, apiGptDeleted } from '@/request/aiApi';

const props = defineProps({
    modelValue: { type: Number, default: null },
    reportConfigId: { type: String, default: '' },
    reportConfig: { type: Object, default: {} },
})
const emits = defineEmits(['update:modelValue', 'change'])

const todayList = ref([]);
const day30List = ref([]);
const historyList = ref([]);

// 获取会话列表
async function getTalkList() {
    const { data } = await apiGptList({ reportConfigId: props.reportConfigId });
    todayList.value = data?.todayList || [];
    day30List.value = data?.day30List || [];
    historyList.value = data?.historyList || [];
}

// 选中
function handleClick(id) {
    emits('update:modelValue', id);
    emits('change', id);
}

// 删除会话
async function delTalk(id) {
    const { code } = await apiGptDeleted({ subjectId: id, reportConfigId: props.reportConfigId });
    if (code !== '0') return;
    getTalkList();
}

getTalkList();

defineExpose({ getTalkList })
</script>

<style lang="scss" scoped>
.le_box {
    height: 100%;
    width: var(--le_width);
    position: absolute;
    top: 0;
    left: 0;
    background-color: #E4EEFB;
    display: flex;
    flex-direction: column;
    --button-border-radius: 8px;
    --color-primary: #005bac;

    .logo {
        width: 100%;
        height: 56px;
        display: flex;
        align-items: center;
        overflow: hidden;

        img {
            max-height: 100%;
            max-width: 100%;
        }
    }

    .new_btn {
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
            background-color: var(--color-primary);
            color: #fff;
            width: 60%;
            border-radius: var(--button-border-radius);
            height: 40px;
            line-height: 40px;
        }
    }

    .list {
        flex: 1;
        overflow-y: auto;

        .list_item {
            padding: 0 8px;
            position: relative;
            padding-bottom: 8px;

            &::after {
                display: block;
                content: '';
                width: 100%;
                position: absolute;
                left: 0;
                bottom: 0;
                border-top: 1px solid #CDDFF2;
                border-bottom: 1px solid #F1F5FA;
            }

            .tit {
                font-size: 12px;
                color: #7F8890;
                line-height: 1em;
                padding: 0 8px;
                padding-top: 12px;
                padding-bottom: 7px;
            }

            ul {
                li {
                    padding: 0 8px;
                    line-height: 34px;
                    user-select: none;
                    font-size: 14px;
                    color: #2A2A2A;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    word-break: break-all;
                    position: relative;

                    .del {
                        display: none;
                        position: absolute;
                        width: 24px;
                        height: 24px;
                        border-radius: 4px;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        align-items: center;
                        justify-content: center;
                        background-color: #f56c6c;

                        .iconfont {
                            color: #fff;
                            font-size: 14px;
                        }
                    }

                    &.active {
                        background-color: var(--color-primary);
                        border-radius: var(--button-border-radius);
                        color: #fff;
                    }

                    &:not(.active) {
                        cursor: pointer;
                    }

                    &:not(.active):hover {
                        color: var(--color-primary);

                        .del {
                            display: flex;
                        }
                    }
                }
            }
        }
    }
}
</style>