<template>
    <div class="home_container">
        <!-- 右上角定位用户名称和下拉菜单 -->
        <UserMenu :userName="userName" @password="ChangePasswordShow = true" />
        <!-- 左侧栏 -->
        <LeftBox ref="leBox" v-model="subjectId" :reportConfigId="reportConfigId" :reportConfig="reportConfig"
            @change="setChatList" />
        <section class="ri_box">
            <div class="content_box scrollbar">
                <ul v-if="chatList.length">
                    <li v-for="(item, index) in chatList" :key="index">
                        <div class="talk user">
                            <div class="name_row">
                                <div class="head_img">
                                    <i class="iconfont icon-user"></i>
                                </div>
                                <span>{{ userName }}</span>
                            </div>
                            <div class="content" v-if="!item.edit">{{ item.question }}</div>
                            <div class="edit_box" v-else>
                                <AutoTextarea v-model="editVal" @submit="saveEdit" />
                            </div>
                            <div class="operate">
                                <div class="icon_btn" v-if="!item.edit" @click="openEdit(index, chatList)">
                                    <i class="iconfont icon-bianji"></i>
                                </div>
                                <div class="icon_btn" v-if="item.edit" @click="saveEdit(index, chatList)">
                                    <i class="iconfont icon-duihao"></i>
                                </div>
                                <div class="icon_btn" v-if="item.edit" @click="closeEdit(index, chatList)">
                                    <i class="iconfont icon-cuowuguanbiquxiao"></i>
                                </div>
                            </div>
                        </div>
                        <div class="talk ai">
                            <div class="name_row">
                                <div class="head_img">
                                    <img src="@/assets/img/ai/ai_head.png" alt="" srcset="">
                                </div>
                                <span>{{ reportConfig?.aiName || '机器人' }}</span>
                            </div>
                            <div class="content" v-if="chatList.length === index + 1 && chatLoading">正在思考中，请稍等...</div>
                            <div class="content" v-else v-html="md.render(item.answer)"></div>
                            <div class="operate" v-if="!(index + 1 === chatList.length && chatLoading)">
                                <div class="icon_btn" @click="copyAnswer(index, chatList)">
                                    <i class="iconfont icon-fuzhi"></i>
                                </div>
                                <div class="icon_btn" :class="item.operate === 1 ? 'active' : ''"
                                    @click="handleLike(index, chatList, 1)">
                                    <i class="iconfont icon-dianzan"></i>
                                </div>
                                <div class="icon_btn" :class="item.operate === 2 ? 'active' : ''"
                                    @click="handleLike(index, chatList, 2)">
                                    <i class="iconfont icon-cai"></i>
                                </div>
                                <div class="icon_btn" v-if="chatList.length === index + 1"
                                    @click="handleReply(index, chatList)">
                                    <i class="iconfont icon-shuaxin"></i>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="no_data" v-else>
                    <div class="logo"><img src="@/assets/img/ai/nodata_logo.png" alt="" srcset=""></div>
                    <p>有什么能帮到你的？</p>
                </div>
            </div>
            <div class="input_box">
                <div class="input">
                    <AutoTextarea v-model="val" @submit="onInputQuestion" />
                    <el-button class="send" v-loading="chatLoading" @click="onInputQuestion">
                        <i class="iconfont icon-fasong"></i>
                    </el-button>
                </div>
            </div>
        </section>
    </div>
    <!-- 修改密码 -->
    <ChangePassword v-model:show="ChangePasswordShow" />
</template>

<script setup>
import { ref, nextTick } from 'vue';
import AutoTextarea from './AutoTextarea.vue';
import UserMenu from './UserMenu.vue';
import LeftBox from './LeftBox.vue';
import withLoading from '@/utils/loading';
import { apiGptAsk, apiGptSubject } from '@/request/aiApi';
import { ElMessage } from 'element-plus';
import { scrollToBottom, html2text } from '@/utils';
import useOperate from './useOperate';
import ChangePassword from './ChangePassword.vue';
import MarkdownIt from 'markdown-it';
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';
import useReportConfig from './useReportConfig';
import { useRoute } from 'vue-router';

const route = useRoute();
const reportConfigId = route.query.reportId;// 报告id
const leBox = ref(null);
const userName = localStorage.getItem('name');
const ChangePasswordShow = ref(false);// 修改密码弹窗
const val = ref('');// 输入问题
const subjectId = ref(null);// 话题id
const chatList = ref([]);// 聊天列表
const chatLoading = ref(false);// 聊天响应loading
const maxLength = 500;// 最大问题长度
const md = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
    highlight: function (str, lang) {
        return hljs.highlight(lang, str).value;
    }
});

// 报告配置
const { reportConfig } = useReportConfig(reportConfigId);

// 获取聊天列表
async function setChatList(id) {
    if (!id) {
        chatList.value = [];
        return;
    }
    const { data } = await withLoading(apiGptSubject)({ subjectId: id, reportConfigId });
    let list = data || [];
    list = list.map((item) => {
        item['edit'] = false;
        return item;
    });
    chatList.value = list;
    nextTick(() => { scrollToBottom('.content_box') });
}

// 下方输入框提问
function onInputQuestion() {
    if (chatLoading.value) return;
    const content = html2text(val.value);
    if (!content) {
        ElMessage.error('内容不能为空哟');
        return;
    }
    if (content.length > maxLength) {
        ElMessage.error(`内容不能超过${maxLength}字`);
        return;
    }
    closeAllEdit();// 关闭所有编辑状态
    // 将提问加入列表
    chatList.value.push({
        question: content,
        edit: false
    });
    askQuestion(content);// 发送请求
    val.value = '';// 清空输入框
    nextTick(() => { scrollToBottom('.content_box') });
}

// 发起提问请求
async function askQuestion(content) {
    chatLoading.value = true;
    try {
        const params = { content, reportConfigId };
        if (subjectId.value) {
            params['subjectId'] = subjectId.value;
        }
        const { code, data } = await apiGptAsk(params);
        if (code !== '0') return;
        // 将回答加入最后一次对话
        const last = chatList.value[chatList.value.length - 1];
        last['answer'] = data?.answer || '';
        last['chatId'] = data?.chatId || null;
        last['subjectId'] = data?.subjectId || null;
        // 如果不存在话题id，说明是新话题
        if (!subjectId.value) {
            subjectId.value = data?.subjectId || null;
            leBox.value.getTalkList();// 刷新话题列表
        }
        nextTick(() => {
            setTimeout(() => {
                scrollToBottom('.content_box');
            }, 10)
        });
    } finally {
        chatLoading.value = false;
    }
}

// 操作对话
const { editVal, openEdit, closeEdit, saveEdit, closeAllEdit, copyAnswer, handleLike, handleReply } = useOperate(reportConfigId, chatLoading, chatList, askQuestion, maxLength);

(() => {
    // 如果存在话题id，获取聊天列表
    subjectId.value && setChatList(subjectId.value);
})
</script>

<style lang="scss" scoped>
.home_container {
    --le_width: 240px; // 左边宽度

    height: 100vh;
    position: relative;
    padding-left: var(--le_width);
    background-color: #F1F5FA;
    --button-border-radius: 8px;
    --color-primary: #005bac;

    .ri_box {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;

        .content_box {
            flex: 1;
            overflow-y: auto;
            padding: 64px 0;

            ul {
                width: 800px;
                max-width: 100%;
                margin: 0 auto;

                li {
                    margin-bottom: 16px;

                    .talk {
                        &:first-child {
                            margin-bottom: 16px;
                        }

                        .name_row {
                            display: flex;
                            height: 22px;

                            .head_img {
                                width: 22px;
                                height: 22px;
                                border-radius: 50%;
                                margin-right: 10px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                overflow: hidden;
                                background-color: #E4EEFB;

                                .iconfont {
                                    color: var(--color-primary);
                                    font-size: 14px;
                                }

                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            span {
                                font-size: 14px;
                                color: #7F8890;
                                line-height: 16px;
                                user-select: none;
                            }
                        }

                        .content {
                            padding-left: 32px;
                            color: #2A2A2A;
                            font-size: 14px;
                            line-height: 24px;

                            // white-space: pre-wrap; // 使\n换行符生效
                            :deep(ul),
                            :deep(li),
                            :deep(ol) {
                                list-style: decimal;
                            }

                            :deep(ol),
                            :deep(ul) {
                                padding-left: 1em;
                            }

                            :deep(pre) {
                                border: 1px solid #ccc;
                                background-color: #f2f2f2;
                                border-radius: 4px;
                                padding: 8px 10px;
                                overflow-y: auto;
                            }

                            :deep(table) {
                                border-collapse: collapse;

                                th,
                                td {
                                    border: 1px solid #ccc;
                                    border-radius: 10px;
                                    padding: 4px 8px;
                                    text-align: center;
                                }
                            }
                        }

                        .operate {
                            padding-left: 26px;
                            height: 26px;
                            display: flex;
                            align-items: center;
                            margin-top: 4px;

                            .icon_btn {
                                cursor: pointer;
                                width: 26px;
                                height: 26px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                transition: 0.2s;

                                .iconfont {
                                    color: #999;
                                    font-size: 16px;
                                }

                                &.active {
                                    .iconfont {
                                        color: var(--color-primary);
                                    }
                                }

                                &:hover {
                                    transform: scale(1.1);
                                }
                            }
                        }

                        .edit_box {
                            padding-left: 32px;
                            margin-top: 8px;

                            :deep(.textarea) {
                                outline: 1px solid #ccc;
                                border-radius: 8px;
                                background-color: #fff;

                                &:focus {
                                    outline: 1px solid var(--color-primary);
                                    outline-offset: -1px;
                                }
                            }
                        }
                    }
                }
            }

            .no_data {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 200px;

                .logo {
                    width: 120px;
                    height: 120px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                p {
                    padding-top: 16px;
                    color: #2A2A2A;
                    font-size: 40px;
                    transform: translateX(16px);
                }

            }
        }

        .input_box {
            background-color: #fff;
            padding: 18px 0;
            display: flex;
            justify-content: center;

            .input {
                width: 800px;
                border-radius: 8px;
                background-color: #F1F5FA;
                overflow: hidden;
                position: relative;
                padding-right: 52px;

                .send {
                    width: 52px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: var(--color-primary);
                    position: absolute;
                    top: 0;
                    right: 0;
                    border-radius: 0;
                    border: none;

                    .iconfont {
                        color: #fff;
                        font-size: 26px;
                    }
                }

                :deep(.textarea) {
                    &:focus {
                        outline: 1px solid var(--color-primary);
                        outline-offset: -1px;
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                }
            }
        }
    }
}
</style>