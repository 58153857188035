<template>
    <el-dialog :model-value="show" title="修改密码" width="500px" @close="handleClose">
        <el-form ref="formRef" :model="form" :rules="rules" label-position="top">
            <el-form-item label="原密码" prop="oldLoginPwd">
                <el-input type="password" placeholder="请输入" v-model.trim="form.oldLoginPwd" />
            </el-form-item>
            <el-form-item label="新密码" prop="newLoginPwd">
                <el-input type="password" placeholder="请输入" v-model.trim="form.newLoginPwd" />
            </el-form-item>
            <el-form-item label="确认新密码" prop="checkPwd">
                <el-input type="password" placeholder="请输入" v-model.trim="form.checkPwd" />
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="handleClose">取消</el-button>
                <el-button type="primary" :loading="loading" @click="submitForm(formRef)">提交</el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script setup>
import { reactive, ref } from 'vue';
import { apiUserReset, authLogout } from '@/request/api';
import { ElMessage } from 'element-plus';

defineProps({ show: { type: Boolean, default: false } })
const emits = defineEmits(['update:show'])

const formRef = ref(null);
const loading = ref(false);
const form = reactive({
    oldLoginPwd: '',
    newLoginPwd: '',
    checkPwd: ''
})
const validateCheckPwd = (_, value, callback) => {
    if (!value) {
        callback(new Error('请再次输入新密码'))
    } else if (value !== form.newLoginPwd) {
        callback(new Error("两次输入的密码不一致"))
    } else {
        callback()
    }
}
const rules = reactive({
    oldLoginPwd: [{ required: true, message: '请输入', trigger: 'blur' }],
    newLoginPwd: [{ required: true, message: '请输入', trigger: 'blur' }],
    checkPwd: [{ validator: validateCheckPwd, required: true, trigger: 'blur' }],
})

async function submitForm(formEl) {
    if (!formEl) return
    await formEl.validate((valid) => {
        if (!valid) return;
        handleSubmit();
    })
}

// 提交
async function handleSubmit() {
    loading.value = true;
    try {
        const params = {
            oldPassword: form.oldLoginPwd,
            newPassword: form.newLoginPwd,
        };
        const { code } = await apiUserReset(params);
        if (code !== '0') return;
        ElMessage.success('修改成功');
        authLogout();
        localStorage.clear();
        window.location.href = '/';
    } finally {
        loading.value = false;
    }
}

function handleClose() {
    loading.value = false;
    formRef.value.resetFields();
    emits('update:show', false);
}
</script>