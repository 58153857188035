import { get, post } from './http';

// 会话列表
export const apiGptList = (p) => post('/api/gpt/list', p);
// 前台获取会话主题历史对话
export const apiGptSubject = (p) => get('/api/gpt/subject', p);
// 提问
export const apiGptAsk = (p) => post('/api/gpt/ask', p);
// 点赞-点踩
export const apiGptLike = (p) => post('/api/gpt/like', p);
// 重新回答
export const apiGptReply = (p) => post('/api/gpt/reply', p);
// 删除会话
export const apiGptDeleted = (p) => post('/api/gpt/deleted', p);
// 获取配置
export const apiGptSetting = (p) => get('/api/gpt/setting', p);