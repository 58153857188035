<template>
    <div class="user_menu">
        <el-dropdown size="large" @command="handleCommand">
            <div class="dropdown_box">
                <div class="head_img">
                    <i class="iconfont icon-user"></i>
                </div>
                <span>{{ userName }}</span>
            </div>
            <template #dropdown>
                <el-dropdown-menu>
                    <el-dropdown-item command="PASSWORD">修改密码</el-dropdown-item>
                    <el-dropdown-item command="EXIT">退出登录</el-dropdown-item>
                </el-dropdown-menu>
            </template>
        </el-dropdown>
    </div>
</template>

<script setup>
import { authLogout } from '@/request/api';

defineProps({ userName: { type: String, default: '用户名' } });
const emits = defineEmits(['password'])

function handleCommand(command) {
    switch (command) {
        case 'PASSWORD':
            emits('password');
            break;
        case 'EXIT':
            authLogout();
            localStorage.clear();
            window.location.reload();
            break;
        default:
            break;
    }
}

</script>

<style lang="scss" scoped>
.user_menu {
    position: fixed;
    right: 0;
    top: 0;
    height: 64px;
    display: flex;
    align-items: center;

    .dropdown_box {
        padding-right: 40px;
        outline: none;
        cursor: pointer;
        display: flex;
        align-items: center;

        .head_img {
            background-color: #E4EEFB;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            margin-right: 4px;

            .iconfont {
                font-size: 20px;
                color: var(--color-primary);
            }
        }

        span {
            font-size: 16px;
            color: #2A2A2A;
        }
    }
}
</style>